<template>
  <footer
    component-name="app-footer"
    class="flex flex-col items-stretch w-full border-t border-current gap-4xl px-edge bg-skin-subtle text-skin-base pt-4xl pb-3xl"
    data-skin="sand"
  >
    <div class="grid w-full grid-cols-12 gap-x-ui gap-y-4xl">
      <wrapper-reveal
        tag="section"
        class="w-full col-span-full phablet:col-span-6 tablet:col-span-3"
        reveal="fade-from-down"
      >
        <btn-mirage />
      </wrapper-reveal>

      <wrapper-reveal
        tag="section"
        class="flex flex-col w-full col-span-full phablet:col-span-6 tablet:col-span-3 gap-2xl"
        reveal="fade-from-bottom"
      >
        <div
          v-html="$t.labels.get_in_touch"
          class="leading-none text-overline"
        />
        <div class="flex flex-col justify-start gap-xl">
          <btn-nav
            class="self-start"
            :title="$t.labels.contacts"
            :action="{ to: '/contacts' }"
          />
          <btn-nav
            v-if="storeLocatorEnabled"
            class="self-start"
            :title="$t.labels.store_locator"
            :action="{ to: '/store-locator' }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.faq"
            :action="{ to: '/faq' }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.work_with_us"
            :action="{
              href: `https://zinrec.intervieweb.it/gruppoconcorde/jobs/candidatura_spontanea_498/${
                $route.params.locale === 'it' ? 'it' : 'en'
              }`,
            }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.info_request"
            :action="{ to: '/support' }"
          />
        </div>
      </wrapper-reveal>

      <wrapper-reveal
        tag="section"
        class="flex flex-col w-full col-span-full phablet:col-span-6 tablet:col-span-3 gap-2xl"
        reveal="fade-from-bottom"
      >
        <div v-html="$t.labels.follow_us_on" class="text-overline/none"></div>

        <div class="flex flex-col justify-start gap-xl">
          <btn-nav
            class="self-start"
            :title="$t.labels.instagram"
            :action="{ href: 'https://www.instagram.com/mirage_it' }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.linkedin"
            :action="{ href: 'https://www.linkedin.com/company/mirage-spa' }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.pinterest"
            :action="{ href: 'https://www.pinterest.it/Mirageit' }"
          />
          <btn-nav
            class="self-start"
            :title="$t.labels.youtube"
            :action="{ href: 'https://www.youtube.com/c/mirageitofficial' }"
          />
        </div>
      </wrapper-reveal>

      <wrapper-reveal
        tag="section"
        class="flex flex-col w-full col-span-full phablet:col-span-6 tablet:col-span-3 gap-2xl"
        reveal="fade-from-bottom"
      >
        <div v-html="$t.labels.info_and_legal" class="text-overline/none"></div>

        <div class="flex flex-col justify-start gap-xl">
          <btn-nav
            class="self-start"
            :title="$t.labels.privacy_policy"
            :action="{ to: '/info-and-legal/privacy-policy' }"
          />

          <btn-nav
            class="self-start"
            :title="$t.labels.cookie_policy"
            :action="{ to: '/info-and-legal/cookie-policy' }"
          />

          <btn-nav
            class="self-start iubenda-cs-preferences-link"
            :title="$t.labels.review_cookie_settings"
          />

          <btn-nav
            v-if="
              $route.params.country === 'it' && $route.params.locale === 'it'
            "
            class="self-start"
            :title="$t.labels.whistleblowing"
            :action="{ to: '/info-and-legal/whistleblowing' }"
          />

          <btn-nav
            class="self-start"
            :title="$t.labels.terms_of_sale"
            :action="{ to: '/info-and-legal/terms-of-sale' }"
          />

          <btn-nav
            class="self-start"
            :title="$t.labels.copyright_disclaimer"
            :action="{ to: '/info-and-legal/copyright-disclaimer' }"
          />
        </div>
      </wrapper-reveal>
    </div>

    <wrapper-reveal
      reveal="fade-from-bottom"
      tag="section"
      class="flex items-baseline justify-between w-full gap-2xl"
    >
      <txt-copyright class="text-body" />

      <btn-nav
        :title="$t.labels.credits"
        :action="{
          href: 'https://thinkingabout.it/?utm_source=Mirage&utm_medium=referral',
        }"
      />
    </wrapper-reveal>
  </footer>
</template>

<script setup>
const { storeLocatorEnabled } = useGlobals();

// const route = useRoute();
// const reviewCookiesEnabled = ref(false);
// onMounted(() => {
//   const cookiePolicyId = {
//     de: "42278443",
//     en: "51771646",
//     us: "48467840",
//     es: "35687818",
//     it: "37559117",
//     ru: "79906284",
//     fr: "77596532",
//   };
//   const cookies = document.cookie.split("; ");
//   const iubendaCookie = cookies.find((cookie) =>
//     cookie.startsWith(`_iub_cs-${cookiePolicyId[route.params.locale]}`)
//   );
//   reviewCookiesEnabled.value = iubendaCookie ? true : false;
// });
</script>
