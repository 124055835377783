export const useScrollTracker = () => {
  const { pushDataLayer } = useDataLayer();

  let percentTracker = {
    25: false,
    50: false,
    75: false,
    90: false,
  };

  function update(perc) {
    if (perc >= 90 && percentTracker[90] === false) {
      pushDataLayer({
        event: "scroll_90",
      });
      percentTracker[90] = true;
    } else if (perc >= 75 && percentTracker[75] === false) {
      pushDataLayer({
        event: "scroll_75",
      });
      percentTracker[75] = true;
    } else if (perc >= 50 && percentTracker[50] === false) {
      pushDataLayer({
        event: "scroll_50",
      });
      percentTracker[50] = true;
    } else if (perc >= 25 && percentTracker[25] === false) {
      pushDataLayer({
        event: "scroll_25",
      });
      percentTracker[25] = true;
    }
  }

  function reset() {
    percentTracker = {
      25: false,
      50: false,
      75: false,
      90: false,
    };
  }

  return {
    update,
    reset,
  };
};
