<template>
  <div class="contents">
    <!-- MAIN -->
    <div class="flex flex-col w-full h-auto min-h-screen max-w-screen">
      <!-- HEADER -->
      <app-header class="fixed top-0 left-0 z-10 w-full" />
      <!-- end HEADER -->
      <!-- PAGE -->
      <main id="main-content" class="w-full">
        <nuxt-page
          :transition="{
            mode: 'out-in',
            css: false,
            onBeforeEnter: (el) => {
              $emit('onBeforeEnter', el);
            },
            onEnter: (el, done) => {
              onEnter(el, done);
              $emit('onEnter', el, done);
            },
            onAfterEnter: (el, done) => {
              onAfterEnter(el, done);
              $emit('onAfterEnter', el, done);
            },
            onEnterCancelled: (el) => {
              $emit('onEnterCancelled', el);
            },
            onBeforeLeave: (el) => {
              $emit('onBeforeLeave', el);
            },
            onLeave: onLeave,
            onAfterLeave: (el) => {
              $emit('onAfterLeave', el);
            },
            onLeaveCancelled: (el) => {
              $emit('onLeaveCancelled', el);
            },
          }"
        />
      </main>
      <!-- end PAGE -->

      <!-- PAGE CURTAIN -->
      <div
        v-show="showPageCurtain"
        aria-hidden="true"
        ref="tpl_pageCurtain"
        class="fixed inset-0 w-screen h-screen z-2 bg-skin-base"
      ></div>
      <!-- end PAGE CURTAIN -->
    </div>
    <!-- end MAIN -->

    <!-- FOOTER -->
    <app-footer class="w-full max-w-screen" />
    <!-- end FOOTER -->
  </div>
</template>

<script setup>
import { useRootStore } from "@/stores/root";

const { setAppInert } = useGlobals();
const rootStore = useRootStore();

const tpl_pageCurtain = templateRef("tpl_pageCurtain");

const nuxtApp = useNuxtApp();
const showPageCurtain = ref(false);

const { update: scrollTrackerUpdate, reset: scrollTrackerReset } =
  useScrollTracker();

const { start: modalBlockerStart, stop: modalBlockerStop } = useModalBlocker();

let tl;

const onLeave = (el, done) => {
  if (tl) {
    tl.kill();
  }

  if (!rootStore.needsScrollReset) {
    done();
    return;
  }

  tl = gsap.timeline({
    onStart: () => {
      showPageCurtain.value = true;
    },
    onComplete: () => {
      done();
    },
  });

  tl.fromTo(
    tpl_pageCurtain.value,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.4,
      ease: "power2.inOut",
    }
  );
};

const onEnter = (el, done) => {
  scrollTrackerReset();
  done();
};

const onAfterEnter = (el) => {
  if (tl) {
    tl.kill();
  }

  if (!rootStore.needsScrollReset) {
    // back button navigation
    ScrollTrigger.refresh(true);
    showPageCurtain.value = false;
    rootStore.setNeedsScrollReset(true);
    setAppInert(false);
    return;
  }

  nuxtApp.$lenis.stop();

  tl = gsap.timeline({
    onStart: () => {
      ScrollTrigger.refresh(true);
    },
    onComplete: () => {
      showPageCurtain.value = false;
      rootStore.setNeedsScrollReset(true);
      nuxtApp.$lenis.start();
      setAppInert(false);
    },
  });

  tl.call(() => {
    tl.pause();
    if (nuxtApp.$lenis.scroll === 0) {
      ScrollTrigger.refresh();
      tl.resume();
    } else {
      nuxtApp.$lenis.scrollTo(0, {
        force: true,
        lock: true,
        immediate: true,
        onComplete: () => {
          ScrollTrigger.refresh();
          tl.resume();
        },
      });
    }
  });

  tl.to(
    tpl_pageCurtain.value,
    {
      opacity: 0,
      duration: 0.55,
      ease: "power2.inOut",
    },
    0.4
  );
};

onMounted(() => {
  // LENIS SCROLL

  nuxtApp.$lenis.on("scroll", onLenisScroll);

  ///////////////////////////////
  // Scroll blocker for modals //
  ///////////////////////////////

  modalBlockerStart(nuxtApp.$lenis, showPageCurtain);

  if (nuxtApp.$lenis.isStopped) {
    nuxtApp.$lenis.start();
  }
});

onBeforeUnmount(() => {
  nuxtApp.$lenis.off("scroll", onLenisScroll);
  gsap.ticker.remove();
  modalBlockerStop();
  scrollTrackerReset();
});

function onLenisScroll(event) {
  ScrollTrigger.update();
  scrollTrackerUpdate(
    Math.round(
      (window.scrollY / (event.dimensions.scrollHeight - window.innerHeight)) *
        100
    )
  );
}
</script>
